import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const SectionFunctionsMeta = {
    title: "Funkce",
    shortTitle: "Funkce",
    path: Path.section_functions,
    element: (sectionNumber:string) => <SectionFunctions sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionFunctions({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionFunctionsMeta.title}>
        <p>
        Tato kapitola se věnuje reálným funkcím jedné reálné proměnné.
        </p>
        </ChapterWrapper>
    );
}


import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';
import Footer from '~/Footer';

// import mnoziny from '../assets/images/mnoziny.png';
// const mnoziny = require('../assets/images/mnoziny.png');



export const SectionFunctionsMeta = {
    title: "Funkce",
    shortTitle: "Funkce",
    path: Path.section_functions,
    element: (sectionNumber:string) => <SectionFunctions sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionFunctions({sectionNumber}: {sectionNumber: string}) {

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{SectionFunctionsMeta.title}</h1>

        <p>
        Tato kapitola se věnuje reálným funkcím jedné reálné proměnné.
        </p>

        <Footer/>
        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}


import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';

export const SectionIntegrationMeta = {
    title: "Integrály",
    shortTitle: "Integrály",
    path: Path.section_integration,
    element: (sectionNumber:string) => <SectionIntegration sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionIntegration({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionIntegrationMeta.title}>
        <p>
        Tato kapitola se věnuje integrálům.
        </p>
        </ChapterWrapper>
    );
}


import React, { useState } from 'react';

const ImageCarousel: React.FC<{ images: string[] }> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };

    return (
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
            <img src={images[currentIndex]} style={{ width: "80%" }} alt={`Image ${currentIndex + 1}`} />
            <div style={{ marginTop: 10 }}>
                <button onClick={handlePrev}>&larr;</button>
                <span style={{ margin: '0 15px' }}>{currentIndex + 1}/{images.length}</span>
                <button onClick={handleNext}>&rarr;</button>
            </div>
        </div>
    );
};

export default ImageCarousel;

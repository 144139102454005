import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const BasicsEquationsMeta = {
    title: "Rovnice",
    shortTitle: "Rovnice",
    path: Path.basics_equations,
    element: (sectionNumber:string) => <BasicsEquations sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [];


export function BasicsEquations({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BasicsEquationsMeta.title} TOCSpec={TOCSpec}>

        <ExternalLink href={"https://www.matweb.cz/rovnice/"}>matweb: co je to rovnice</ExternalLink> <br/>
        <ExternalLink href={"https://www.matweb.cz/upravy-rovnic/"}>matweb: úpravy rovnic</ExternalLink>

        <p>Rovnice je ve tvaru
        {`$$
            L = P
        $$`}
        Uprostřed je znak rovnosti '='.<br/>
        Vše, co je nalevo od něj, je levá strana (zkratka L).<br/>
        Vše, co je napravo od něj, je pravá strana (zkratka P).<br/>
        </p>
        <p>Příklad:
        {`$$
            2x + 3 = \\sin x + \\frac{1}{x}
        $$`}
        L: {"$2x + 3$"}<br/>
        P: {"$\\sin x + \\frac{1}{x}$"}<br/>
        </p>

        <p>
        Jakoukoli úpravu rovnice provádíme tak, že stejnou operaci
        provedeme <strong>s celou levou stranou</strong> i <strong>s celou pravou stranou</strong>:

        {`$$
            \\text{operace}(L) = \\text{operace}(P)
        $$`}
        </p>

        <h2>Operace: Přičtení výrazu</h2>
        <p>
        {`$$
            L + a = P + a
        $$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & x^2 + 2x &= 2  \\\\
            L + 1 &= P + 1  & (x^2 + 2x) + 1 &= (2) + 1  \\\\
                    &          & x^2 + 2x + 1 &= 3
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & x^2 + 2x &= 2  \\\\
            L - 2 & = P - 2  & (x^2 + 2x) - 2 &= (2) -2  \\\\
                               & & x^2 + 2x - 2 &= 0
        \\end{align*}$$`}
                {`$$\\begin{align*}
            L & = P          & x^2 &= x + 1 \\\\
            L - (x+1) & = P - (x+1)  & (x^2) - (x+1) &= (x + 1) - (x + 1)  \\\\
                               & & x^2 - x - 1 &= 0
        \\end{align*}$$`}
        Sem patří i odečtení výrazu: Odečtení výrazu {"$a$"} je to stejné jako přičtení výrazu {"$-a$"}.
        </p>

        <h2>Operace: Vynásobení výrazem</h2>
        <p>
        {`$$
           a \\cdot L = a \\cdot P
        $$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & 2x + 1&= 4  \\\\
            3 \\cdot L &= 3 \\cdot P  & 3 \\cdot (2x + 1) &= 3 \\cdot 4  \\\\
                    &          & 6x + 3 &= 12
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & 2\\sin x &= \\cos x  \\\\
            \\cos x \\cdot L & = \\cos x \\cdot P  & \\cos x \\cdot 2\\sin x &= \\cos x \\cdot \\cos x  \\\\
                                 & & 2\\sin x \\cos x &= \\cos^2 x
        \\end{align*}$$`}
        Sem patří i dělení výrazem: Dělení výrazem {"$a$"} je to stejné jako vynásobení výrazem {"$\\frac{1}{a}$"}.
        </p>

        <h2>Operace: Logaritmování</h2>
        <p>
        {`$$\\begin{align*}
           \\ln(L) &= \\ln(P) \\quad && \\small{\\text{přirozený logaritmus - základ e}} \\\\
           \\log(L) &= \\log(P) \\quad && \\small{\\text{dekadický logaritmus - základ 10}} \\\\
          \\log_a(L) &= \\log_a(P) \\quad && \\small{\\text{obecně logaritmus - základ a}}
        \\end{align*}$$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & 2x + 1&= e^{2x}  \\\\
            \\ln(L) &= \\ln(P)  & \\ln(2x + 1) &= \\ln(e^{2x})  \\\\
                    &          & \\ln(2x + 1) &= 2x
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & 10^x &= 2^{x+4}  \\\\
            \\log(L) &= \\log(P)  & \\log(10^x) &= \\log(2^{x+4})  \\\\
                    &          & x  &= (x+4) \\log(2)
        \\end{align*}$$`}
        </p>

        <h2>Operace: Odlogaritmování ('e na')</h2>
        <p>
        {`$$\\begin{align*}
           e^{L} &= e^{P} \\\\
           10^{L} &= 10^{P} \\\\
           a^{L} &= a^{P}
        \\end{align*}$$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & 2x - 3 &= \\ln (x + 1)  \\\\
            e^{L} &= e^{P}  & e^{2x - 3} &= e^{\\ln (x + 1)}  \\\\
                    &          & e^{2x - 3} &= x + 1
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & \\log x &= 2  \\\\
            10^{L} &= 10^{P}  & 10^{\\log x} &= 10^2  \\\\
                    &          & x &= 100
        \\end{align*}$$`}
        </p>

        <h2>Operace: Aplikace prosté funkce</h2>
        <p>
        {`$$
           f(L) = f(P)
        $$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & x + 1 &= 2  \\\\
            L^3 &= P^3  & (x + 1)^3 &= 2^3
        \\end{align*}$$`}
        Funkce {"$f(x) = x^3$"} je prostá. POZOR: Funkce {"$f(x) = x^2$"} není prostá - viz níže.
        {`$$\\begin{align*}
            L & = P        & \\arcsin(x) &= \\frac{\\pi}{2}  \\\\
            \\sin(L) &= \\sin(P)  & \\sin\\Big(\\arcsin(x)\\Big) &= \\sin\\Big(\\frac{\\pi}{2}\\Big)  \\\\
                    &          & x &= 1
        \\end{align*}$$`}
        Pozn.: Funkce {"$\\sin$"} není prostá. Je ale prostá na oboru hodnot L, tj. {"$\\langle -\\frac{\\pi}{2}; \\frac{\\pi}{2}\\rangle$"})
        i P, proto ji můžeme použít.
        </p>
        <p>POZOR: Pokud funkce {"$f$"} není prostá, aplikací operace
        {`$$
           f(L) = f(P)
        $$`}
        obecně získáme nějaká nová 'řešení', která nejsou řešením původní rovnice (!).
        {`$$\\begin{align*}
            L & = P        & x &= 2 \\\\
            L^2 &= P^2  & x^2 &= 2^2
        \\end{align*}$$`}
        Rovnice {"$x^2 = 4$"} má dvě řešení: {"$x = 2$"} a {"$x = -2$"}.
        Původní rovnice {"$x = 2$"} má ale jen jedno řešení: {"$x = 2$"}.
        Úprava nás tedy vede na scestí - není to ekvivalentní úprava.
        </p>
        <Footer/>
        </ChapterWrapper>
    );
}


import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const FunctionLineMeta = {
    title: "Přímka",
    shortTitle: "Přímka",
    path: Path.function_line,
    element: (sectionNumber:string) => <FunctionLine sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fline-summary",
];


export function FunctionLine({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionLineMeta.title} TOCSpec={TOCSpec}>
        <h2 id="fline-summary">...</h2>

        <Footer/>
        </ChapterWrapper>
    );
}


import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';


export const FunctionQuadraticMeta = {
    title: "Parabola",
    shortTitle: "Parabola",
    path: Path.function_quadratic,
    element: (sectionNumber:string) => <FunctionQuadratic sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fquadra-summary",
    "fquadra-notation",
    "fquadra-roots",
    "fquadra-completion",
    "fquadra-completion-roots",
    "fquadra-x12",
    "fquadra-integral",
];


// https://en.wikipedia.org/wiki/Completing_the_square#Geometric_perspective

// TODO cross check against Polák

export function FunctionQuadratic({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionQuadraticMeta.title} TOCSpec={TOCSpec}>

        <h2 id="fquadra-summary">Shrnutí</h2>

        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Tvar</th>
                    <th>Použití</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$ax^2 + bx + c$"}</td>
                    <td></td>
                    <td>obecný tvar</td>
                </tr>
                <tr>
                    <td>{"$a(x - x_0)^2 + y_0$"}</td>
                    <td>vrchol paraboly {"$(x_0, y_0)$"}</td>
                    <td><strong>doplněno na čtverec</strong></td>
                </tr>
                <tr>
                    <td>{"$a(x - x_1)(x - x_2)$"}</td>
                    <td>kořeny {"$x_1, x_2$"}</td>
                    <td><strong>roloženo na kořenové činitele</strong></td>
                </tr>
            </tbody>
        </table>

        <p>Z obecného tvaru doplníme na čtverec pomocí {`$x_0 = -\\frac{b}{2a}, \\ y_0 = c - \\frac{b^2}{4a}$`}.</p>
        <p>Z obecného tvaru rozložíme na kořenové činitele pomocí {"$x_{1,2} = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}$"}.</p>
        <p>Na obecný tvar převádíme jednoduše roznásobením.</p>

        <p>
        Tvar doplněný na čtverec se opticky chová buď jako {"$r^2 + s^2$"}, pak nelze rozložit;
        nebo jako {"$r^2 - s^2$"}, pak lze rozložit na {"$(r - s)(r + s)$"}. <i>(Standardní
        zápis {"$r^2 - s^2$"} jsme zde nechtěli použít, protože symboly {"$a, b$"} by kolidovaly s koeficienty {"$a, b$"} v kvadratickém polynomu.)</i>
        </p>

        <p>
        <strong>Doplnění na čtverec lze provést vždy</strong> (parabola má vždy vrchol),&nbsp;
        <strong>rozložení na kořenové činitele ne</strong>: rozložení v {"$\\mathbb{R}$"} lze provést
        jen pokud {"$D = b^2 - 4ac \\geq 0$"} (parabola nemusí mít průsečíky nebo dotek s osou {"$x$"}).
        </p>

        <p>
        Pro {"$a > 0$"} je parabola otevřená směrem nahoru (konvexní), pro {"$a < 0$"} je parabola otevřená směrem dolů (konkávní).
        Kořeny jsou symetricky vlevo/vpravo od vrcholu paraboly (symetricky položené vůči {"$x_0$"}).
        </p>
        {/* {"$x_1 + x_2 = -\\frac{b}{a}$"}, {"$x_1x_2 = \\frac{c}{a}$"} */}

        <p>
        Pozor: Polynom {"$(x+1)(x+2)$"} má kořeny {"$-1, -2$"}, nikoli {"$1, 2$"}!
        Při pohledu na závorku si říkáme: "Co dosadit za {"$x$"}, aby se závorka vynulovala?" &rarr;
        To je kořen. Opticky: {"$(-1 + 1) = 0$"} a {"$(-2 + 2) = 0$"}.
        Kořeny můžeme 'natvrdo' odečítat jen z tvaru {"$(x - x_1)(x - x_2)$"}, což je {"$\\big(x - (-1)\\big)\\big(x - (-2)\\big)$"}.
        </p>

        <h2 id="fquadra-notation">Terminologie a souvislosti</h2>

        <p>
        Výraz typu
        {`$$
            ax^2 + bx + c
        $$`}
        se nazývá <strong>polynom stupně 2</strong> (za předpokladu, že {`$a \\neq 0$`}). <br/>
        </p>

        <p>
        <i>(Proč dodáváme požadavek {`$a \\neq 0$`}? Kdyby bylo {`$a = 0$`}, pak bychom měli polynom {`$bx + c$`}, který má stupeň 1,
        a o takovém nechceme tvrdit, že je to polynom stupně 2.)</i>
        </p>

        <p>Když tento polynom položíme rovný nule:
        {`$$
            ax^2 + bx + c = 0,
        $$`}
        získali jsme <strong>kvadratickou rovnici</strong>.
        </p>

        <p>
        <strong>Kořen</strong> kvadratického polynomu je takové číslo {`$x$`}, pro které platí {`$ax^2 + bx + c = 0$`};
        kořen je řešením kvadratické rovnice.
        </p>

        <p>
        Když tento polynom dáme za rovnítko v definici funkce:
        {`$$
            f(x) = ax^2 + bx + c,
        $$`}
        získali jsme <strong>kvadratickou funkci</strong>.
        </p>

        {/* kde {`$a$`}, {`$b$`} a {`$c$`} jsou reálná čísla a {`$a \\neq 0$`}. Proč píšeme {`$a \\neq 0$`}? Kdyby bylo {`$a = 0$`},
        pak bychom měli funkci {`$f(x) = bx + c$`}, která je lineární, nikoli kvadratická. */}

        <p>
        <strong>Nulové body</strong> funkce jsou body, kde graf funkce protíná osu {`$x$`}, tj. kde {`$f(x) = 0$`}.
        </p>

        <p>Když položíme {"$f(x) = ax^2 + bx + c = 0$"}, tak vidíme, že nulové body kvadratické funkce jsou právě kořeny onoho kvadratického polynomu,
        a řešení oné kvadratické rovnice. Jsou to body, kde parabola protíná osu {"$x$"}.
        </p>

        <h2 id="fquadra-roots">Kořeny</h2>

        <p>
        Víme, že polynom stupně {"$n$"} má přesně {"$n$"} kořenů v komplexním oboru (každý kořen počítáme tolikrát, kolik je jeho násobnost).
        Některé z těcho kořenů mohou být čistě reálné, ostatní jsou komplexní (a po dvojicích komplexně sdružené).
        </p>

        <p>
        Polynom druhého stupně může mít:
        </p>

        <ul>
            <li>Dva různé reálné kořeny.</li>
            <li>Jeden dvojnásobný reálný kořen.</li>
            <li>Žádný reálný kořen (tj. dva komplexně sdružené kořeny).</li>
        </ul>

        <p>
        Jsou-li {"$x_1$"} a {"$x_2$"} kořeny, pak kvadratický polynom lze zapsat jako
        {`$$
            ax^2 + bx + c = a(x - x_1)(x - x_2).
        $$`}
        Jednotlivým závorkám {"$(x - x_i)$"} se říká <strong>kořenové činitele</strong>. Po roznásobení máme:
        {`$$\\begin{align*}
            a(x - x_1)(x - x_2) &= ax^2 \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &- a(x_1 + x_2)x & \\ + \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &ax_1x_2 \\\\
                                &= ax^2 \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &+            bx & \\ + \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &c
        \\end{align*}$$`}
        </p>

        {/* <p>Pokud je {"$a=1$"}, pak je
        {`$$\\begin{align*}
             1(x - x_1)(x - x_2) &= 1\\cdot x^2 \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &- (x_1 + x_2)x & \\ + \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &x_1x_2 \\\\
                                 &= 1\\cdot x^2 \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &-            bx & \\ + \\! \\! \\! \\! \\! \\! \\! \\! \\! \\! &c
        \\end{align*}$$`}
        Vidíme, že {"$b = x$"} a {"$c$"} jsou součty kořenů a součin kořenů, respektive.
        </p> */}

        <p>
        Vidíme, že {"$b = -a(x_1 + x_2)$"} a {"$c = ax_1x_2$"}. Tyto vztahy s nazývají&nbsp;
        <a href={"https://cs.wikipedia.org/wiki/Vi%C3%A8tovy_vzorce"} target="_blank" rel="noopener noreferrer" className="external-link">Viètovy vzorce</a>.
        Ověřme zpětně, jak nám vyjdou kořeny:
        {`$$\\begin{align*}
            x_{1,2} = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a} & = \\frac{a(x_1 + x_2) \\pm \\sqrt{a^2(x_1 + x_2)^2 - 4ax_1x_2}}{2a} = \\\\
                                                            & = \\frac{x_1 + x_2 \\pm \\sqrt{(x_1 + x_2)^2 - 4x_1x_2}}{2} = \\\\
                                                            & = \\frac{x_1 + x_2 \\pm \\sqrt{x_1^2 - 2x_1x_2 + x_2^2}}{2} = \\\\
                                                            & = \\frac{x_1 + x_2 \\pm \\sqrt{(x_1 - x_2)^2}}{2} = \\\\
                                                            & = \\frac{x_1 + x_2 \\pm |x_1 - x_2|}{2} = \\begin{cases} x_1 \\\\ x_2 \\end{cases}
        \\end{align*}$$`}
        </p>


        <p>
        <strong>Normovaný tvar</strong> je tvar kdy {"$a = 1$"}.
        Při řešení kvadratické rovnice lze polynom převést do normovaného tvaru tím, že rovnici vydělíme koeficientem {"$a$"}:
        {`$$\\begin{align*}
            ax^2 + bx + c &= 0 \\quad &&\\rightarrow \\quad &x^2 + \\frac{b}{a}x + \\frac{c}{a} &= 0. \\\\
            3x^2 - 3x - 6 &= 0 \\quad &&\\rightarrow \\quad &x^2 - x - 2 &= 0.
        \\end{align*}$$`}
        Normovaný tvar se hodí pro zjednodušení výpočtu, pokud {"$b$"} a {"$c$"} jsou dělitelné {"$a$"}.
        </p>

        <p>
        V normovaném tvaru se dobře "vizuálně odhadují kořeny": Součin závorek typu {"$(x + p)(x + q)$"} vede na {"$x^2 + (p+q)x + pq$"},
        takže hledáme dvě čísla {"$p, q$"} tak, aby {"$pq = c$"} a {"$p+q=b$"}.
        Pokud je najdeme, pak kořeny jsou {"$-p, -q$"} protože rozklad na kořenové činitele je {"$(x - (-p))(x - (-q))$"}.
        </p>
        V příkladě výše vidíme že {"$1\\cdot (-2) = -2 = c$"}, a {"$1 + (-2) = -1 = b$"}, takže rozklad je {"$(x + 1)(x - 2) = (x - (-1))(x - 2)$"},
        a kořeny jsou {"$-1, 2$"}.
        <p>
        </p>
        <p>
        Pozn.: Tento postup odpovídá použití Vietových vzorců {"$x_1x_2 = c$"} a {"$-(x_1 + x_2) = b$"}.
        Pokud bychom chtěli kořeny najít takto přímo,
        hledali bychom ta dvě čísla {"$p, q$"} tak, aby {"$p+q=\\textcolor{red}{-}b$"} a {"$pq=c$"};
        pak by kořeny byly přímo {"$p, q$"} a rozklad by byl {"$(x - p)(x - q)$"}.
        Pokud to někdo dělá takto, je to úplně OK, záleží na zvyku.
        </p>

        <h2 id="fquadra-completion">Doplnění na čtverec</h2>

        <p>
        Jedná se o převod z tvaru {"$ax^2 + bx + c$"} do tvaru
        {`$$
            a(x - x_0)^2 + y_0,
        $$`}
        kde
        {`$$
            x_0 = - \\frac{b}{2a}, \\qquad y_0 = c - \\frac{b^2}{4a}.
        $$`}
        </p>
        <p>
        Ukazuje se, že tato technika se opakovaně hodí v různých situacích.
        </p>

        <ul>
            <li><p>Pro kreslení grafu kvadratické funkce: Bod {`$(x_0, y_0)$`} je vrchol paraboly.</p></li>
            <li><p>Pro řešení integrálu typu {"$\\int \\frac{1}{ax^2 + bx + c}\\text{d} x$"}</p></li>
            <li><p>Pro odvození slavného vzorce {"$x_{1,2}=\\ldots$"}</p></li>
        </ul>

        <p>Postup: 1. Pomocný krok. Všiměme si, že
        {`$$\\begin{align*}
            \\textcolor{red}{\\Big(x + \\frac{p}{2}\\Big)^2} = x^2 + 2\\frac{p}{2}x + \\Big(\\frac{p}{2}\\Big)^2 = \\textcolor{green}{x^2 + px} + \\textcolor{blue}{\\frac{p^2}{4}},
        \\end{align*}$$`}
        a tedy že
        {`$$
            \\textcolor{green}{x^2 + px} = \\textcolor{red}{\\Big(x + \\frac{p}{2}\\Big)^2} - \\textcolor{blue}{\\frac{p^2}{4}}.
        $$`}
        </p>
        <p>Postup: 2. Provedeme doplnění:
        {`$$\\begin{align*}
            &ax^2 + bx + c =  \\\\
            = \\ & a\\Big( \\textcolor{green}{x^2 + \\frac{b}{a}x} \\Big) + c \\\\
            = \\ & a\\Bigg[   \\textcolor{red}{\\Big(x + \\frac{(\\frac{b}{a})}{2}\\Big)^2} -  \\textcolor{blue}{\\frac{(\\frac{b}{a})^2}{4}}  \\Bigg] + c \\\\
            = \\ & a \\Big(x + \\frac{b}{2a}\\Big)^2 - a\\frac{b^2}{4a^2} + c \\\\
            = \\ & a \\Bigg(x - \\textcolor{violet}{\\Big(-\\frac{b}{2a}\\Big)}\\Bigg)^2 + \\textcolor{maroon}{c - \\frac{b^2}{4a}} \\\\
            = \\ & a \\Big(x - \\textcolor{violet}{x_0}\\Big)^2 + \\textcolor{maroon}{y_0}.
        \\end{align*}$$`}
        </p>

        <p>
        Doplnění na čverec je výborně ilustrované na&nbsp;
        <a href={"https://cs.wikipedia.org/wiki/Dopln%C4%9Bn%C3%AD_na_%C4%8Dtverec"} target="_blank" rel="noopener noreferrer" className="external-link">Wikipedii</a>.
        </p>

        <h2 id="fquadra-completion-roots">Kořeny získané z tvaru doplněného na čtverec</h2>

        <p>Hledáme kořeny:
        {`$$\\begin{align*}
            a(x - x_0)^2 + y_0 & = 0 \\\\
             (x - x_0)^2       & = -\\frac{y_0}{a} \\\\
                x - x_0        & = \\pm\\sqrt{-\\frac{y_0}{a}} \\\\
                x              & = x_0 \\pm\\sqrt{-\\frac{y_0}{a}}.
        \\end{align*}$$`}
        </p>

        <p>
        Všiměme si, že kořeny jsou na ose {"$x$"} situovány symetricky okolo hodnoty {`$x_0$`} - nacházejí se
        na pozicích {`$x_0 + \\sqrt{-\\frac{y_0}{a}}$`} a {`$x_0 - \\sqrt{-\\frac{y_0}{a}}$`}.
        Hodnota {`$x_0$`} je x-ová souřadnice vrcholu paraboly - <strong>kořeny jsou tedy symetricky vlevo/vpravo od vrcholu paraboly</strong>.
        </p>

        <p>Kořeny exitují jen tehdy, je-li pod odmocninou nezáporné číslo, tedy když {"$\\frac{y_0}{a} \\leq 0$"}.</p>
        <p>V normalizovaném tvaru je {"$a = 1$"}, takže podmínka se redukuje na {"$y_0 \\leq 0$"}, a je velice názorná. Dejme tomu, že řešíme rovnici
        {`$$
            \\textcolor{green}{(x - 1)^2 - 4} = 0.
        $$`}
        Podmínka {"$y_0 = -4 \\leq 0$"} je splněna, takže postup funguje:
        {`$$\\begin{align*}
            (x - 1)^2 - 4 & = 0 \\\\
            (x - 1)^2 & = 4 \\\\
            x - 1 & = \\pm 2 \\\\
            x_{1,2} & = -1,3.
        \\end{align*}$$`}
        Místo tohoto mechanického postupu ale můžeme použít vzorec {"$\\textcolor{green}{r^2 - s^2} = (r-s)(r+s)$"}:
        {`$$\\begin{align*}
            \\textcolor{green}{r^2 - s^2} & = 0 \\\\
            \\textcolor{green}{(x - 1)^2 - 4} & = 0 \\\\
            \\textcolor{green}{(x - 1)^2 - 2^2} & = 0 \\\\
            (x - 1 - 2)(x - 1 + 2) & = 0 \\\\
            (x - 3)(x + 1) & = 0 \\\\
            x_{1,2} & = -1,3
        \\end{align*}$$`}
        Pokud bychom naopak řešili rovnici
        {`$$
            \\textcolor{red}{(x - 1)^2 + 4} = 0,
        $$`}
        tak vidíme, že je tvaru {"$\\textcolor{red}{r^2 + s^2}$"}, což <span style={{color: 'red'}}>nelze rozložit</span>!
        Rovnou tedy vidíme, že když je {"$y_0 > 0$"}, tak kořeny neexistují.
        </p>
        <p>
        Ve tvaru doplněném na čtverec je pro {"$a > 0$"} parabola
        {`$$
            f(x) = a(x - x_0)^2 + y_0.
        $$`}
        rozevřená směrem nahoru, a {"$y_0 > 0$"} říká, že vrchol je vertikálně nad osou {"$x$"} - i z tohoto je jasné,
        že kořeny (průsečíky s osou {"$x$"}) neexistují. Analogicky, pokud je {"$a < 0$"}, je parabola otevřená směrem dolů,
        a je-li {"$y_0 < 0$"}, tak kořeny neexistují - vzorec je typu {"$\\textcolor{red}{- r^2 - s^2 = - (r^2 + s^2)}$"}, nelze rozložit.
        </p>
        <p>Podmínka {"$\\frac{y_0}{a} \\leq 0$"} pro existenci kořenů vlastně říká, že buď je {"$y_0 = 0$"} (parabola se dotýká osy {"$x$"}),
        anebo {"$y_0$"} a {"$a$"} mají opačná znaménka - parabola je tedy buď otevřená směrem nahoru ({"$a > 0$"}) a vrchol je pod osou ({"$y_0 < 0$"}),
        nebo je otevřená směrem dolů ({"$a < 0$"}) a vrchol je nad osou ({"$y_0 > 0$"}).
        </p>
        {/*
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>{"$a$"}</th>
                    <th>{"$y_0$"}</th>
                    <th>Typ</th>
                    <th>Rozklad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$a > 0$"}</td>
                    <td>{"$y_0 > 0$"}</td>
                    <td>{"$r^2 + s^2$"}</td>
                    <td>neexistuje</td>
                </tr>
                <tr>
                    <td>{"$a > 0$"}</td>
                    <td>{"$y_0 \\leq 0$"}</td>
                    <td>{"$r^2 - s^2$"}</td>
                    <td>{"$(x - x_0 - \\\\frac{})(x - x_0 + b)$"} (dvojnásobný kořen)</td>
            </tbody>
        </table> */}


        <h2 id="fquadra-x12">Odvození vzorce pro kořeny kvadratické rovnice</h2>

        <p>
        {`$$\\begin{align*}
            ax^2 + bx + c &= 0 \\qquad / \\text{doplníme na čtverec} \\\\
            a \\Big(x + \\frac{b}{2a}\\Big)^2 + c - \\frac{b^2}{4a} &= 0 \\qquad / \\cdot 4a \\\\
            4a^2 \\Big(x + \\frac{b}{2a}\\Big)^2 + 4ac - b^2 &= 0 \\qquad \\\\
            \\Bigg[\\Big(2a\\Big)\\Big(x + \\frac{b}{2a}\\Big)\\Bigg]^2  &= b^2 - 4ac \\qquad \\\\
            \\Big(2ax + b\\Big)^2  &= b^2 - 4ac \\qquad \\\\
            2ax + b   &= \\pm\\sqrt{b^2 - 4ac} \\\\
            2ax &= - b \\pm\\sqrt{b^2 - 4ac} \\\\
            x &= \\frac{- b \\pm \\sqrt{b^2 - 4ac}}{2a}
        \\end{align*}$$`}
        </p>


        <h2 id="fquadra-integral">Integrál {"$\\int \\frac{1}{ax^2 + bx + c}\\text{d} x$"}</h2>

        <p>Spočítáme diskriminant {"$D = b^2 - 4ac$"}. Pak postupujeme jednou nebo druhou cestou:</p>

        <p>1. Rozložení na kořenové činitele:<br/>
        Pokud {"$D \\geq 0$"}, pak máme dva reálné kořeny {"$x_1, x_2$"}, a jmenovatele <strong>rozložíme na kořenové činitele</strong>.
        Dostaneme integrál {"$\\int \\frac{1}{a(x - x_1)(x - x_2)}\\text{d} x$"}, a pokračujeme <strong>rozkladem na parciální zlomky</strong>.
        (Pokud je {"$x_1 = x_2$"}, pak máme {"$\\int \\frac{1}{a(x - x_1)^2}\\text{d} x$"}, a integrujeme přímo.)
        </p>

        <p>2. Doplnění na čtverec:<br/>
        Pokud {"$D < 0$"}, pak jmenovatele nelze rozložit na kořenové činitele - <strong>doplníme ho na čtverec</strong>.
        Dostaneme integrál {"$\\int \\frac{1}{a(x - x_0)^2 + y_0}\\text{d} x$"}, a víme že vede na {"$\\bf \\text{arctg}$"} /dosadíme do vzorce/.
        Nebo pokračujeme <strong>substitucí</strong> {"$(x - x_0) = \\sqrt{\\frac{y_0}{a}}\\text{tg} \\, t$"}, která vede na {"$\\text{arctg}$"}.
        Pozn. {"$y_0$"} zde vždy vyjde kladné.
        </p>

        <p>Pro úplnost uvádíme i integrály:
        {`$$
            \\int \\frac{1}{a(x - x_1)^2}\\text{d} x = - \\frac{1}{a} (x - x_1)^{-1} + c,
        $$`}
        {`$$
            \\int \\frac{1}{a(x - x_1)(x - x_2)}\\text{d} x = \\frac{1}{a}\\frac{1}{x_1 - x_2} \\ln \\Bigg|\\frac{x - x_1}{x - x_2}\\Bigg| + c,
        $$`}

        {`$$
            \\int \\frac{1}{a(x - x_0)^2 + y_0}\\text{d} x = \\frac{1}{\\sqrt{a y_0}} \\text{arctg} \\Bigg(\\frac{x - x_0}{\\sqrt{\\frac{y_0}{a}}}\\Bigg) + c.
        $$`}
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}


import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';


export const FunctionNotationMeta = {
    title: "Značení",
    shortTitle: "Značení",
    path: Path.function_notation,
    element: (sectionNumber:string) => <FunctionNotation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fn-argplusminus",
    "fn-argpower",
];

// vysvětlit to od kruhu, napsat že bod patří kruhu K ; pak napsat (x,y) \in f
//

export function FunctionNotation({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionNotationMeta.title} TOCSpec={TOCSpec}>
        <h2 id="fn-argplusminus">Kam až sahá argument?</h2>

        <p>
        Pokud argument není v závorce, symbol součtu {"$+$"} a rozdílu {"$-$"} ukončuje argument.

        {`$$\\begin{align*}
            \\sin x + 1 & \\neq \\sin(x + 1) \\\\
            \\sin x + 1 & = \\sin(x) + 1 = 1 + \\sin x
        \\end{align*}$$`}


        Pokud argument není v závorce, násobení a dělení neukončuje argument.
        {`$$\\begin{align*}
            \\sin xy & = \\sin(xy) \\\\
            \\sin \\frac{x}{y}\\ & = \\sin\\Big(\\frac{x}{y}\\Big)
        \\end{align*}$$`}

        </p>

        <h2 id="fn-argpower">Mocníme argument nebo funkční hodnotu?</h2>

        <p>
        Exponent je u argumentu - týká se argumentu:
        {`$$\\begin{align*}
            \\sin x^2 &= \\sin(x^2) = \\sin(x\\cdot x) \\\\
            \\sin(x+1)^2 &= \\sin\\Big[(x+1)^2\\Big]
        \\end{align*}$$`}
        Exponent je u funkce - týká funkční hodnoty. Rovněž pokud je celá funkce v závorce
        a exponent je u závorky, týká se funkční hodnoty.
        {`$$\\begin{align*}
            \\sin^2 x &= (\\sin x)^2 = \\sin x \\cdot \\sin x
        \\end{align*}$$`}
        </p>
        <p>
        Máme tedy např.:
        {`$$\\begin{align*}
            \\log 10^3 & = \\log(10^3) = \\log 1000 = 3 \\\\
            \\log^3 10 & = (\\log 10)^3 = 1^3 = 1
        \\end{align*}$$`}
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}


import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';
import Footer from '~/Footer';

// const angles = require('../assets/images/angles.png');

export const FunctionLineMeta = {
    title: "Přímka",
    shortTitle: "Přímka",
    path: Path.function_line,
    element: (sectionNumber:string) => <FunctionLine sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fline-summary",
];


export function FunctionLine({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{FunctionLineMeta.title}</h1>

        <h2 id="fline-summary">...</h2>

        <Footer/>
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}


import * as d3 from "d3";
import { addGroupForAxes, CLS_MAIN, plotAxes, type PlotProps } from "./LinearSystemPlot";

export const CLS_FUNC = "func";

export function addGroupFor(contentGroup: d3.Selection<SVGGElement, unknown, null, any>, key: string) {
    contentGroup.append("g").attr("class", key);
}

export function selectGroupFunc(): d3.Selection<SVGGElement, unknown, HTMLElement, any> {
    return d3.select(`g.${CLS_FUNC}`);
}

export interface FunctionPack {
    f: (x: number) => number;
    fderivative1: (x: number) => number;
}

export function initPlot(
    svgElement: SVGSVGElement | null,
    func: FunctionPack,
    x0: number,
    plotProps: PlotProps,
) {
    if (!svgElement) return;

    // Clear the previous graph
    const svg = d3.select(svgElement);
    svg.selectAll("*").remove();

    // Top-level content group
    const mainGroup = svg.append("g").attr("class", CLS_MAIN);
    addGroupForAxes(mainGroup);
    addGroupFor(mainGroup, CLS_FUNC);

    // Create the zoom behavior
    const zoom = d3.zoom().on("zoom", (event) => {
        // Update the scales' domains based on the zoom event
        const newXScale = event.transform.rescaleX(plotProps.xScale);
        const newYScale = event.transform.rescaleY(plotProps.yScale);

        plotProps.currentXScale = newXScale;
        plotProps.currentYScale = newYScale;

        // Update the graph using the new scales
        zoomGraph(
            {
                width: plotProps.width,
                height: plotProps.height,
                margin: plotProps.margin,
                xScale: newXScale,
                yScale: newYScale,
                currentXScale: newXScale,
                currentYScale: newYScale,
            },
            func,
            x0,
        );
    });

    svg.call(zoom as any);

    redrawGraph(svgElement, plotProps, func, x0);
}

// Function to redraw the graph with updated scales
export function redrawGraph(
    svgElement: SVGSVGElement,
    plotProps: PlotProps,
    func: FunctionPack,
    x0: number,
) {
    plotAxes(plotProps);
    plotFunction(func, x0, plotProps);
}

// Function to redraw the graph with updated scales
export function zoomGraph(plotProps: PlotProps, func: FunctionPack, x0: number) {
    plotAxes(plotProps);
    plotFunction(func, x0, plotProps);
}

//
// Plot the function graph
//
export function plotFunction(func: FunctionPack, x0: number, plotProps: PlotProps) {
    const g = selectGroupFunc();
    g.selectAll("*").remove();

    const [xScale, yScale] = [plotProps.currentXScale, plotProps.currentYScale];
    const [xmin, xmax] = xScale.domain();

    // Plot the function curve
    const x_coords = d3.range(xmin, xmax, (xmax - xmin) / 100);
    const points = x_coords.map((x) => [x, func.f(x)]);

    plotProps.height;

    // Plot the function curve
    const line = d3
        .line<number[]>()
        .x((d) => xScale(d[0]))
        .y((d) => yScale(d[1]));

    g.append("path")
        .datum(points)
        //.attr("clip-path", "url(#chart-area)")
        .attr("fill", "none")
        .attr("stroke", "teal")
        .attr("stroke-width", 2)
        .attr("d", line);

    g.append("circle")
        .attr("cx", xScale(x0))
        .attr("cy", yScale(func.f(x0)))
        .attr("r", 5)
        .attr("fill", "red");

    const slope = func.fderivative1(x0);
    const tangent = (x: number) => func.f(x0) + slope * (x - x0);
    g.append("line")
        .attr("x1", xScale(xmin))
        .attr("y1", yScale(tangent(xmin)))
        .attr("x2", xScale(xmax))
        .attr("y2", yScale(tangent(xmax)))
        .attr("stroke", "green")
        .attr("stroke-width", 2);

    // append a tick at x0 on the x-axis
    g.append("line")
        .attr("x1", xScale(x0))
        .attr("y1", yScale(0) + plotProps.height * 0.02)
        .attr("x2", xScale(x0))
        .attr("y2", yScale(0) - plotProps.height * 0.02)
        .attr("stroke", "red")
        .attr("stroke-width", 1);
}


import React, { useState, useEffect } from "react";

import type { FunctionPack } from "./FunctionPlot";
import { initPlot } from "./FunctionPlot";
import { MathJaxWrapper } from "~/MathJaxWrapper";
import { MathJax } from "better-react-mathjax";
import { PlotProps, resetZoomWithoutAccess } from "./LinearSystemPlot";


export interface FunctionPackExt extends FunctionPack {
    f_tex: string;
    f_derivative1_tex: string;
}

// Predefined cases
export const predefinedCases: FunctionPackExt[] = [
    {
        f: (x) => x ** 2,
        fderivative1: (x) => 2 * x,
        f_tex: "x^2",
        f_derivative1_tex: "2x",
    },
    {
        f: (x) => Math.sin(x),
        fderivative1: (x) => Math.cos(x),
        f_tex: "\\sin(x)",
        f_derivative1_tex: "\\cos(x)",
    },
    {
        f: (x) => Math.exp(x),
        fderivative1: (x) => Math.exp(x),
        f_tex: "e^x",
        f_derivative1_tex: "e^x",
    },
    {
        f: (x) => Math.log(x),
        fderivative1: (x) => 1 / x,
        f_tex: "\\ln(x)",
        f_derivative1_tex: "\\frac{1}{x}",
    },
    {
        f: (x) => Math.atan(x),
        fderivative1: (x) => 1 / (1 + x ** 2),
        f_tex: "\\arctan(x)",
        f_derivative1_tex: "\\frac{1}{1 + x^2}",
    },
];

export function CaseCard({
    index,
    caseData,
    handleSelectCase,
}: {
    index: number;
    caseData: FunctionPackExt;
    handleSelectCase: (selection: FunctionPackExt) => void;
}) {
    return (
        <MathJaxWrapper>
            <MathJax dynamic={true}>
                <div key={index} className="case-entry">
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                        {`$$\\begin{align*}
                            f(x) &= ${caseData.f_tex} \\\\
                            f'(x) &= ${caseData.f_derivative1_tex}
                        \\end{align*}$$`}
                    </p>
                    <button onClick={() => handleSelectCase(caseData)}>Select</button>
                </div>
            </MathJax>
        </MathJaxWrapper>
    );
}

// Graph component to visualize the equations and iterations
export function FunctionDisplay({
    spec,
    plotProps,
    initial_x0,
}: {
    spec: FunctionPackExt;
    plotProps: PlotProps;
    initial_x0: number;
}) {
    // Input state variables
    const [x0, setx0] = useState(initial_x0);

    const [funcPack, setFuncPack] = useState(spec);

    // State for the expandable cases section
    const [isExpanded, setIsExpanded] = useState(false);

    // D3 SVG ref
    const svgRef = React.useRef<SVGSVGElement>(null);

    useEffect(() => {
        initPlot(svgRef.current, funcPack, x0, plotProps);
    }, [funcPack, x0]);


    // Function to handle selecting a predefined case
    const handleSelectCase = (func: FunctionPackExt) => {
        plotProps.currentXScale = plotProps.xScale; //reset scale
        plotProps.currentYScale = plotProps.yScale; //reset scale
        resetZoomWithoutAccess(svgRef.current);
        setIsExpanded(false); // Close the expandable section
        setFuncPack(func);
    };


    return (
        <div>
            {/* Expandable Section */}
            <div style={{ marginBottom: 20 }}>
                <button onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? "Hide Predefined Cases" : "Show Predefined Cases"}
                </button>
                {isExpanded && (
                    <div className="expandable-section">
                        {predefinedCases.map((caseData, index) => (
                            <CaseCard
                                key={index}
                                index={index}
                                caseData={caseData}
                                handleSelectCase={handleSelectCase}
                            />
                        ))}
                    </div>
                )}
            </div>

            <div className="input-container">
                <div>
                    <label>x0:</label>
                        <input
                            type="number"
                            value={x0}
                            onChange={(e) => setx0(parseFloat(e.target.value))}
                            step={0.1}
                        />
                </div>
            </div>

            <svg ref={svgRef} width={plotProps.width} height={plotProps.height} />
        </div>
    );
}

import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const SectionDerivativesMeta = {
    title: "Limity, derivace",
    shortTitle: "Limity, derivace",
    path: Path.section_derivatives,
    element: (sectionNumber:string) => <SectionDerivatives sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionDerivatives({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionDerivativesMeta.title}>
        <p>
        Tato kapitola se věnuje derivacím.
        </p>

        </ChapterWrapper>
    );
}


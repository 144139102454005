import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';

export const SectionBasicsMeta = {
    title: "Základy",
    shortTitle: "Základy",
    path: Path.section_basics,
    element: (sectionNumber:string) => <SectionBasics sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionBasics({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionBasicsMeta.title}>
        <p>
        Tato kapitola se věnuje derivacím.
        </p>
        </ChapterWrapper>
    );
}




export enum Path {
    root = "/",
    //
    section_basics = "section-basics",
    section_derivatives = "section-derivatives",
    section_functions = "section-functions",
    section_integration = "section-integration",
    //
    angles = "angles",
    basics_equations = "basics-equations",
    derivatives_1 = "derivatives-1",
    function_graph_transformations = "function-graph-transformations",
    function_graph_transformations2 = "function-graph-transformations2",
    function_line = "function-line",
    function_notation = "function_notation",
    function_parity = "function-parity",
    function_quadratic = "function-quadratic",
    functions_notes = "functions-notes",
    goniometric = "goniometric",
    limits = "limits",
    substitution = "substitution",

    tangent_line = "tangent-line",

    integration1 = "integration1",
}
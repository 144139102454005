import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Plot from 'react-plotly.js';
import { Layout } from 'plotly.js';

import Slider from 'rc-slider';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';

import 'rc-slider/assets/index.css';
import Footer from '~/Footer';

const a_fx = require('../assets/images/a-fx.png');
const graph_shift = require('../assets/images/graph_shift.png');
const graph_compression = require('../assets/images/graph_compression.png');
const graph_centralsym = require('../assets/images/graph_centralsym.png');


function rangeGrid(a: number, b:number, step:number): number[] {
    return Array.from({length: Math.round((b-a)/step)}, (_, i) => a + i * step);
}

// TODO: handle cases af + x, a f(ax+b)
// t = ax + b = a(x + b/a)
// f(x + b/a) ... posunuto o b/a
// f(a(x + b/a)) ... posunutá funkce je roztažená ax ... původní bod 0 je posunutý na -b/a a pak na b

// f(ax) bod 0 je 0
// f(t + b)



export const FunctionGraphTransformationsMeta = {
    title: "Grafy: transformace",
    shortTitle: "Grafy: transformace",
    path: Path.function_graph_transformations,
    element: (sectionNumber:string) => <FunctionGraphTransformations sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fgt-summary",
    "fgt-fx-plusc",
    "fgt-f-xplusc",
    "fgt-a-fx",
    "fgt-f-ax",
    "fgt-minus-fx",
    "fgt-f-minusx",
    "fgt-minusf-minusx",
    "fgt-linfunc",
];



interface FunctionPlotWithParamProps {
    f: (x: number, a: number) => number,
    flabel: (a: number) => string,
    formula: string,
    formulaScaled:string
    width: number;
    height: number;
    xRange: [number, number];
    yRange: [number, number];
    initialA: number;
    refA?: number;
    alabel?: string;
    hideSlider?: boolean;
}

// a*Math.sin(k)
function FunctionPlotWithParam(
    props: FunctionPlotWithParamProps
) {

    const {f, flabel, formula, formulaScaled, width, height, xRange, yRange, initialA, refA, alabel, hideSlider} = props;

    const [a, setA] = useState<number>(initialA);
    const [ref_a, setRef_a] = useState<number>(refA !== undefined ? refA : initialA);

    const handleSliderChange = useCallback((value: number | number[]) => {
        if (typeof value === 'number') {
            setA(value);
        }
    }, []);

    const layout = useMemo<Partial<Layout>>(() => ({
        height,
        width,
        margin: {
            l: 50,
            r: 50,
            b: 50,
            t: 0,
            pad: 4
        },
        xaxis: {
            range: xRange,
            scaleanchor: 'y' as 'x' | 'y' | undefined, // Link x-axis scaling to y-axis
        },
        yaxis: {
            range: yRange,
            scaleratio: 1 // Ensure the ratio between scales of y-axis and linked x-axis is 1:1
        },
        aspectratio: {
            x: 1,
            y: 1
        }
    }), [width, height, xRange, yRange]);

    const xdata = useMemo<number[]>(() => rangeGrid(xRange[0], xRange[1], 0.1), []);

    return (
        <div style={{width: "100%", display: "flex", flexDirection: 'column', alignItems: 'center'}}>
            <Plot data={[
                {
                    x: xdata,
                    y: xdata.map(x => f(x,ref_a)),
                    type: 'scatter',
                    mode: 'lines',
                    marker: {
                        color: 'rgb(50, 50, 50)', // 'rgb(150, 205, 191)',
                        opacity: 0.8,
                    },
                    name: `y = ${formula}`
                },
                {
                    x: xdata,
                    y: xdata.map(x => f(x,a)),
                    type: 'scatter',
                    mode: 'lines',
                    marker: {
                        color: 'rgb(200, 50, 50)', // 'rgb(150, 205, 191)',
                        opacity: 0.8,
                    },
                    name: `y = ${formulaScaled}`
                }
            ]}
            layout={layout}
            />
            {/* <div style={{"padding": 5}}>
                <label >
                    a:
                    <input type="number" value={a} onChange={(e) => setA(Number(e.target.value))} />
                </label>
            </div> */}
            {hideSlider ?
                    null :
            <div style={{ padding: 20, width: "50%", height: 20 }}>
                <label>
                    {alabel || 'a'}: {a.toFixed(2)} ; funkce: y = {flabel(a)}
                    <Slider
                        min={-8}
                        max={8}
                        value={a}
                        onChange={handleSliderChange}
                        step={0.1}
                        style={{ width: '100%', height: 20, margin: '20px 0' }}
                    />
                </label>
            </div>
            }
        </div>
    );
}

export function FunctionGraphTransformations({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{FunctionGraphTransformationsMeta.title}</h1>

        <h2 id="fgt-summary">Shrnutí</h2>

        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Transformace vzorce</th>
                    <th>Transformace grafu</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>{"$f(x) \\rightarrow f(x) \\pm c$"}</td><td>vertikální posun (nahoru, dolů)</td></tr>
                <tr><td>{"$f(x) \\rightarrow f(x \\pm c)$"}</td><td>horizontální posun (doleva, doprava)</td></tr>
                <tr><td>{"$f(x) \\rightarrow af(x)$"}</td><td>vertikální škálování (roztažení, komprese)</td></tr>
                <tr><td>{"$f(x) \\rightarrow f(ax)$"}</td><td>horizontální škálování (roztažení, komprese)</td></tr>
                <tr><td>{"$f(x) \\rightarrow -f(x) $"}</td><td>zrcadlení kolem osy {"$x$"}</td></tr>
                <tr><td>{"$f(x) \\rightarrow f(-x) $"}</td><td>zrcadlení kolem osy {"$y$"}</td></tr>
                <tr><td>{"$f(x) \\rightarrow -f(-x) $"}</td><td>středová symetrie podle počátku {"$(0,0)$"}</td></tr>
            </tbody>
        </table>

        <p>Pamatujte si:<br/>
            {"$\\quad f(x - 1)$"} posouvá graf o jednotku <strong>doprava</strong>,<br/>
            {"$\\quad f(x + 1)$"} posouvá graf o jednotku <strong>doleva</strong>.<br/>
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={graph_shift} style={{width: "65%"}}/>
        </div>


        <p>Pamatujte si:<br/>
            {"$\\quad f(2x)$"} graf <strong>komprimuje</strong>,<br/>
            {"$\\quad f(\\frac{1}{2}x)$"} graf <strong>roztahuje</strong>.<br/>
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={graph_compression} style={{width: "61%"}}/>
        </div>

        <p>
        Transformace {"$f(x) \\pm c$"} a {"$af(x)$"} upravují funkční hodnoty (výsledky funkce),
        a dává tedy smysl, že představují modifikace grafu podél osy {"$y$"} (svislé osy, osy hodnot).
        </p>

        <p>
        Transformace {"$f(x \\pm c)$"} a {"$f(ax)$"} "přečíslovávají" argumenty funkce,
        a dává tedy smysl, že představují modifikace grafu podél osy {"$x$"} (vodorovné osy, osy argumentů).
        </p>

        <h2 id="fgt-fx-plusc">{"$f(x) \\rightarrow f(x) \\pm c$"}: vertikální posun</h2>

        <FunctionPlotWithParam
            f={(x,c) => Math.sin(x) + c}
            formula={"sin(x)"}
            formulaScaled={"sin(x) + c"}
            flabel={
                (c: number) => {
                    if (c >= 0) {
                        return `sin(x) + ${c.toFixed(1)}`
                    }
                    else { // (c < 0)
                        return `sin(x) - ${c.toFixed(1)}`
                    }
                }
            }
            width={500}
            height={400}
            xRange={[-4, 4]}
            yRange={[-4, 4]}
            refA={0}
            initialA={0.4}
            alabel='c'
        />

        <h2 id="fgt-f-xplusc">{"$f(x) \\rightarrow f(x \\pm c)$"}: horizontální posun</h2>


        <FunctionPlotWithParam
            f={(x,c) => Math.sin(x + c)}
            formula={"sin(x)"}
            formulaScaled={"sin(x + c)"}
            flabel={
                (c: number) => {
                    if (c >= 0) {
                        return `sin(x + ${c.toFixed(1)})`
                    }
                    else { // (c < 0)
                        return `sin(x - ${(-c).toFixed(1)})`
                    }
                }
            }
            width={500}
            height={200}
            xRange={[-4, 4]}
            yRange={[-1.2, 1.2]}
            refA={0}
            initialA={-0.4}
            alabel='c'
        />

        <h2 id="fgt-a-fx">{"$f(x) \\rightarrow af(x)$"}: vertikální škálování</h2>

        <p>
        Zrcadlení podle osy {"$x$"} je speciální případ vertikálního škálování, kde {"$a = -1$"}.
        </p>

        {/* <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={a_fx} style={{width: "85%"}}/>
        </div> */}

        <FunctionPlotWithParam
            f={(x,a) => a*Math.sin(x)}
            formula={"sin(x)"}
            formulaScaled={"a sin(x)"}
            flabel={(a: number) => `${a.toFixed(1)} sin(x)`}
            width={500}
            height={400}
            xRange={[-4, 4]}
            yRange={[-4, 4]}
            refA={1}
            initialA={1.4}
        />

        <h2 id="fgt-f-ax">{"$f(x) \\rightarrow f(ax)$"}: horizontální škálování</h2>

        <p>
        Zrcadlení podle osy {"$y$"} je speciální případ horizontálního škálování, kde {"$a = -1$"}.
        </p>

        <FunctionPlotWithParam
            f={(x,a) => Math.sin(a*x)}
            formula={"sin(x)"}
            formulaScaled={"sin(ax)"}
            flabel={(a: number) => `sin(${a.toFixed(1)}x)`}
            width={500}
            height={200}
            xRange={[-8, 8]}
            yRange={[-1.2, 1.2]}
            refA={1}
            initialA={1.2}
        />

        <h2 id="fgt-minus-fx">{"$f(x) \\rightarrow -f(x) $"}: zrcadlení kolem osy {"$x$"}</h2>

        <FunctionPlotWithParam
            f={(x,a) => (a * ((x-1)*(x-1) - 2))}
            formula={"(x-1)² - 2"}
            formulaScaled={"-(x-1)² + 2"}
            flabel={(a: number) => ""} // unused
            width={500}
            height={400}
            xRange={[-4, 4]}
            yRange={[-4, 4]}
            initialA={-1}
            refA={1}
            hideSlider={true}
        />

        <h2 id="fgt-f-minusx">{"$f(x) \\rightarrow f(-x) $"}: zrcadlení kolem osy {"$y$"}</h2>

        <FunctionPlotWithParam
            f={(x,a) => ((a*x-1)*(a*x-1) - 2)}
            formula={"(x-1)² - 2"}
            formulaScaled={"(-x-1)² - 2"}
            flabel={(a: number) => ""} // unused
            width={500}
            height={400}
            xRange={[-4, 4]}
            yRange={[-4, 4]}
            initialA={-1}
            refA={1}
            hideSlider={true}
        />

        <h2 id="fgt-minusf-minusx">{"$f(x) \\rightarrow -f(-x) $"}: středová symetrie</h2>

        <FunctionPlotWithParam
            f={(x,a) => (a*((a*x-1)*(a*x-1) - 2))}
            formula={"(x-1)² - 2"}
            formulaScaled={"-[(-x-1)² - 2]"}
            flabel={(a: number) => ""} // unused
            width={500}
            height={400}
            xRange={[-4, 4]}
            yRange={[-4, 4]}
            initialA={-1}
            refA={1}
            hideSlider={true}
        />

        <p>Na středovou symetrii se lze dívat těmito ekvivalentními způsoby:</p>

        <ul>
            <li><p>
            Jedná se o orotování o 180° kolem počátku souřadného systému, tj. kolem bodu (0,0).
            </p></li>
            <li><p>
            Body na grafu se přesunou na své zrcadlené obrazy vůči počátku souřadného systému.
            Bod {"$A$"} na původním grafu spojíme přímkou s bodem {"$(0,0)$"},
            a pak vyznačíme bod na této přímce, který je bodu {"$A$"} přímo naproti vzhledem k bodu {"$(0,0)$"}.
            (Bod {"$(0,0)$"} leží přímo uprostřed mezi bodem {"$A$"} a jeho obrazem.)
            </p></li>
            <li><p>
            Jedná se o složení dvou ozrcadlení - postupné ozrcadlení napřed kolem osy x a pak kolem osy y
            (nebo v opačném pořadí).
            </p></li>
        </ul>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={graph_centralsym} style={{width: "75%"}}/>
        </div>

        <h2 id="fgt-linfunc">Transformace funkce {"$x$"}</h2>

        <p>
        Vezměme {"$f(x) = x$"}, a podívejme se na výraz {"$x + c$"}. Získáme ho dvěma způsoby (interpretacemi):
        {`$$
            x+c = f(x) + c \\qquad  x+c = f(x+c)
        $$`}
        Jednou je to, jako bychom graf funkce {"$y = x$"} posunuli o {"$c$"} nahoru,
        podruhé o {"$c$"} doleva.
        Ověřte si, že v obou případech skončíme se steným grafem!
        </p>
        <p>
        Podívejme se na výraz {"$ax$"}:
        {`$$
            ax = a f(x) \\qquad  ax = f(ax)
        $$`}
        Jednou je to, jako bychom graf funkce {"$y = x$"} roztáhli
        faktorem {"$a$"} ve svislém směru,
        podruhé jako bychom jej komprimovali
        faktorem {"$a$"} ve vodorovném směru.
        Ověřte si, že v obou případech skončíme se steným grafem!
        </p>

        <p>
        Obecná přímka se takto nechová. Např. pro {"$f(x) = 2x$"} máme
        {`$$
            f(x) + c = 2x+c,  \\quad \\text{ale} \\quad f(x+c) = 2x + 2c,
        $$`}
        a pro {"$f(x) = x + 1$"} máme
        {`$$
            af(x) = ax + a,  \\quad \\text{ale} \\quad  f(ax) = ax + 1.
        $$`}
        </p>

        <Footer/>
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}


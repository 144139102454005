import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';


export const GoniometricMeta = {
    title: "Goniometrické funkce a spol.",
    shortTitle: "Sin, cos, ...",
    path: Path.goniometric,
    element: (sectionNumber:string) => <Goniometric sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "gon1",
    "posuny",
    "doublearg",
    "summation",
];

// na prvnim cviku prezencku dle pritomnych, ne nachystanou -> pak nachystat dle pritomnych

export function Goniometric({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={GoniometricMeta.title} TOCSpec={TOCSpec}>

        <h2 id="gon1">Goniometrická jednička</h2>
        {`$$\\begin{align*}
            \\cos^2 x + \\sin^2 x & = 1
        \\end{align*}$$`}
        Představuje Pythagorovu větu v pravoúhlém trojúhelníku s přeponou délky 1. Odvěsny jsou pak {"$\\sin x$"} a {"$\\cos x$"}.

        <h2 id="posuny">Posuny podél osy x</h2>

        {`$$\\begin{align*}
            \\sin\\left(-x\\right) & = - \\sin x & \\cos\\left(-x\\right) & = \\cos x \\\\
            \\sin\\left(x \\pm \\pi\\right) & = - \\sin x  & \\cos\\left(x \\pm \\pi\\right) & = - \\cos x \\\\
            \\sin\\left(x + \\frac{\\pi}{2}\\right) & = \\cos x & \\cos\\left(x + \\frac{\\pi}{2}\\right) & = - \\sin x \\\\
            \\sin\\left(x - \\frac{\\pi}{2}\\right) & = - \\cos x & \\cos\\left(x - \\frac{\\pi}{2}\\right) & = \\sin x
        \\end{align*}$$`}

        <h2 id="doublearg">Dvojnásobný argument</h2>
        {`$$\\begin{align*}
            \\sin 2x  &= 2 \\sin x \\cos x & \\cos 2x &= \\cos^2 x - \\sin^2 x
        \\end{align*}$$`}

        {`$$\\begin{align*}
            \\sin^2 x  &= \\frac{1 - \\cos 2x}{2} & \\cos^2 x  &= \\frac{1 + \\cos 2x}{2}
        \\end{align*}$$`}

        <h2 id="summation">Součtové vzorce</h2>

        {`$$\\begin{align*}
            \\sin(x+y) &= \\sin x \\cos y + \\cos x \\sin y \\\\
            \\sin(x-y) &= \\sin x \\cos y - \\cos x \\sin y \\\\
            \\cos(x+y) &= \\cos x \\cos y - \\sin x \\sin y \\\\
            \\cos(x-y) &= \\cos x \\cos y + \\sin x \\sin y
        \\end{align*}$$`}

        {`$$\\begin{align*}
            \\sin a +  \\sin b &= 2 \\sin \\frac{a+b}{2} \\cos \\frac{a-b}{2} \\\\
            \\sin a -  \\sin b  &= 2 \\cos \\frac{a+b}{2} \\sin \\frac{a-b}{2} \\\\
            \\cos a +  \\cos b &= 2 \\cos \\frac{a+b}{2} \\cos \\frac{a-b}{2} \\\\
            \\cos a -  \\cos b &= - 2 \\sin \\frac{a+b}{2} \\sin \\frac{a-b}{2}
        \\end{align*}$$`}

        {/* \noindent\makebox[\linewidth]{\rule{\textwidth}{0.4pt}} */}

        {`$$\\begin{align*}
            \\vert \\sin x \\vert &= \\sqrt{1 - \\cos^2 x} & \\vert \\cos x \\vert &= \\sqrt{1 - \\sin^2 x}
        \\end{align*}$$`}

        {`$$\\begin{align*}
            \\vert \\sin x \\vert &= \\sqrt{\\frac{1 - \\cos 2x}{2}} & \\vert \\cos x \\vert &= \\sqrt{\\frac{1 + \\cos 2x}{2}} \\\\
            \\Big\\vert \\sin\\frac{x}{2} \\Big\\vert &= \\sqrt{\\frac{1 - \\cos x}{2}} & \\Big\\vert \\cos\\frac{x}{2} \\Big\\vert &= \\sqrt{\\frac{1 + \\cos x}{2}}
        \\end{align*}$$`}

        {`$$\\begin{align*}
            \\sin x \\cos y &= \\frac{1}{2}\\left[\\sin(x+y) + \\sin(x-y)\\right] \\\\
            \\cos x \\sin y &= \\frac{1}{2}\\left[\\sin(x+y) - \\sin(x-y)\\right] \\\\
            \\cos x \\cos y &= \\frac{1}{2}\\left[\\cos(x+y) + \\cos(x-y)\\right] \\\\
            \\sin x \\sin y &= \\frac{1}{2}\\left[\\cos(x+y) - \\cos(x-y)\\right] \\\\
        \\end{align*}$$`}



{/*
\pagebreak

\centering{Poznámky k odvození}


\begin{figure}[!h]
\centerline{\includegraphics[width=10cm]{diagram.png}}
\caption{Autor diagramů:  https://math.stackexchange.com/users/409/blue}
\end{figure}

\noindent\makebox[\linewidth]{\rule{\textwidth}{0.4pt}}

\begin{align*}
\textrm{Vyjdeme z:} \quad & \sin(x+y) = \sin x \cos y + \cos x \sin y, \ \  \textrm{a nechť } y = x \\
\textrm{Důsledek:} \quad & \sin(x+x) = \sin x \cos x + \cos x \sin x  \quad \rightarrow \quad {\color{red}\sin 2x  = 2 \sin x \cos x}
\end{align*}

\begin{align*}
\textrm{Vyjdeme z:} \quad & \cos(x+y) = \cos x \cos y - \sin x \sin y, \ \  \textrm{a nechť } y = x \\
\textrm{Důsledek:} \quad &  \cos(x+x) = \cos x \cos x - \sin x \sin x  \quad \rightarrow \quad {\color{red}\cos 2x = \cos^2 x - \sin^2 x}
\end{align*}


\begin{align*}
\textrm{Vyjdeme z:} \quad && \cos 2x &= \cos^2 x - \sin^2 x \\
\textrm{a z:}     \quad         &&           1 &= \cos^2 x + \sin^2 x \ \ \ \ \textrm{a sečteme rovnice (levé strany a pravé strany)}  \\
\textrm{Důsledek:} \quad  &&  1 + \cos 2x &= (\cos^2 x + \sin^2 x) + (\cos^2 x - \sin^2 x)  \\
\rightarrow             \quad   &&  1 + \cos 2x &= 2 \cos^2 x  \quad \rightarrow \quad {\color{red}\cos^2 x  = \frac{1 + \cos 2x}{2}}
\end{align*}


\begin{align*}
\textrm{Vyjdeme z:} \quad && \cos 2x &= \cos^2 x - \sin^2 x \\
\textrm{a z:}     \quad         &&           1 &= \cos^2 x + \sin^2 x \ \ \ \ \textrm{a odečteme rovnice (levé strany a pravé strany)}  \\
\textrm{Důsledek:} \quad  &&  1 - \cos 2x &= (\cos^2 x + \sin^2 x) - (\cos^2 x - \sin^2 x)  \\
\rightarrow             \quad   &&  1 - \cos 2x &= 2 \sin^2 x  \quad \rightarrow \quad {\color{red}\sin^2 x  = \frac{1 - \cos 2x}{2}}
\end{align*}


\begin{align*}
\textrm{Vyjdeme z:} \quad    && \sin(x+y) &= \sin x \cos y + \cos x \sin y, \qquad \textrm{a dosaďme } (-y) \textrm{ za } y \\
\textrm{Důsledek:} \quad      && \sin\left(x+ (-y)\right) &= \sin x \cos(-y) + \cos x \sin(-y)  \\
\textrm{ + využijeme:} \quad && \Big\vert \cos(-y) &= \cos y, \ \sin(-y) = - \sin y \Big\vert  \\
\rightarrow \quad                  &&  {\color{red}\sin\left(x - y\right) \ } & {\color{red} = \sin x \cos y - \cos x \sin y }
\end{align*}


\begin{align*}
\textrm{Vyjdeme z:} \quad    && \sin(x+y) &= \sin x \cos y + \cos x \sin y, \qquad \textrm{a dosaďme } (y + \frac{\pi}{2}) \textrm{ za } y \\
\textrm{Důsledek:} \quad      && \sin\left(x+ (y + \frac{\pi}{2})\right) &= \sin x \cos(y + \frac{\pi}{2}) + \cos x \sin(y + \frac{\pi}{2})  \\
\textrm{ + využijeme:} \quad && \Big\vert \cos(y + \frac{\pi}{2}) &= -\sin y, \ \sin(y + \frac{\pi}{2}) = \cos y \Big\vert  \\
\rightarrow \quad                  && \sin\left((x+ y) + \frac{\pi}{2}\right) &= \sin x (-\sin y) + \cos x \cos y  \\
\rightarrow \quad                  &&  {\color{red}\cos\left(x + y\right) \ } & {\color{red} = \cos x \cos y - \sin x \sin y }
\end{align*}

\begin{align*}
\textrm{Vyjdeme z:} \quad    && \cos(x+y) &= \cos x \cos y - \sin x \sin y, \qquad \textrm{a dosaďme } (-y) \textrm{ za } y \\
\textrm{Důsledek:} \quad      && \cos\left(x+ (-y)\right) &= \cos x \cos(-y) - \sin x \sin(-y)  \\
\textrm{ + využijeme:} \quad && \Big\vert \cos(-y) &= \cos y, \ \sin(-y) = - \sin y \Big\vert  \\
\rightarrow \quad                  &&  {\color{red}\cos\left(x - y\right) \ } & {\color{red} = \cos x \cos y + \sin x \sin y }
\end{align*}



\begin{align*}
\textrm{Vyjdeme z:} \quad && \sin(x+y) &= \sin x \cos y + \cos x \sin y \\
\textrm{a z:}     \quad         && \sin(x-y) &= \sin x \cos y - \cos x \sin y \\
\textrm{Substituce:} \quad  &&  a &= x + y,  \ b = x - y, \\
                                \quad  &&  x &= \frac{a+b}{2},  \ y = \frac{a-b}{2}, \\
\rightarrow              \quad   && \sin(x+y) + \sin(x-y) & = \left(\sin x \cos y + \cos x \sin y\right) + \left(\sin x \cos y - \cos x \sin y\right) \\
\rightarrow              \quad   && \sin(x+y) + \sin(x-y) & = 2 \sin x \cos y \\
\rightarrow              \quad   &&  {\color{red}\sin a +  \sin b} \ &  {\color{red} = 2 \sin \frac{a+b}{2} \cos \frac{a-b}{2}} \\
\\
\rightarrow              \quad   && \sin(x+y) - \sin(x-y) & = \left(\sin x \cos y + \cos x \sin y\right) - \left(\sin x \cos y - \cos x \sin y\right) \\
\rightarrow              \quad   && \sin(x+y) - \sin(x-y) & = 2 \cos x \sin y \\
\rightarrow              \quad   &&  {\color{red}\sin a -  \sin b} \ &  {\color{red} = 2 \cos \frac{a+b}{2} \sin \frac{a-b}{2}}
\end{align*}


\begin{align*}
\textrm{Vyjdeme z:} \quad && \cos(x+y) &= \cos x \cos y - \sin x \sin y \\
\textrm{a z:}     \quad         && \cos(x-y) &= \cos x \cos y + \sin x \sin y \\
\textrm{Substituce:} \quad  &&  a &= x + y,  \ b = x - y, \\
                                \quad  &&  x &= \frac{a+b}{2},  \ y = \frac{a-b}{2}, \\
\rightarrow              \quad   && \cos(x+y) + \cos(x-y) & = \left(\cos x \cos y - \sin x \sin y\right) + \left(\cos x \cos y + \sin x \sin y\right) \\
\rightarrow              \quad   && \cos(x+y) + \cos(x-y) & = 2 \cos x \cos y \\
\rightarrow              \quad   &&  {\color{red}\cos a +  \cos b} \ &  {\color{red} = 2 \cos \frac{a+b}{2} \cos \frac{a-b}{2}} \\
\\
\rightarrow              \quad   && \cos(x+y) - \cos(x-y) & = \left(\cos x \cos y - \sin x \sin y\right) - \left(\cos x \cos y + \sin x \sin y\right) \\
\rightarrow              \quad   && \cos(x+y) - \cos(x-y) & = - 2 \cos x \sin y \\
\rightarrow              \quad   &&  {\color{red}\cos a -  \cos b} \ &  {\color{red} = - 2 \sin \frac{a+b}{2} \sin \frac{a-b}{2}}
\end{align*}


\noindent\makebox[\linewidth]{\rule{\textwidth}{0.4pt}}

Další zdroje: https://en.wikipedia.org/wiki/List\_of\_trigonometric\_identities */}

        <Footer/>
        </ChapterWrapper>
    );
}

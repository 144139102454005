import React from 'react';

import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";

import './App.css';
import { Section, sections } from './defs';
import { FooterMini } from './Footer';
import { LandingPage } from './LandingPage';
import { ContentsMenu } from './Menu';
import { NavBar } from './NavBar';
import { Path } from './paths';
import { version } from "./version";

import {
    AnglesMeta,
    BasicsEquationsMeta,
    Derivatives1Meta,
    FunctionGraphTransformations2Meta,
    FunctionGraphTransformationsMeta,
    FunctionLineMeta,
    FunctionNotationMeta,
    FunctionParityMeta,
    FunctionQuadraticMeta,
    FunctionsNotesMeta,
    GoniometricMeta,
    Integration1Meta,
    LimitsMeta,
    SectionBasicsMeta,
    SectionDerivativesMeta,
    SectionFunctionsMeta,
    SectionIntegrationMeta,
    SubstitutionMeta,
    TangentLineMeta,
} from './chapters';



const AppLayout = () => {
    const location = useLocation();
    const isHistogramApp = location.pathname.includes('histogram-app');

    return (
        <div className="App" style={{display: "flex", gap: 0, width: "100vw"}}>
            <div className="sidebar" style={{display: "flex", flexDirection: "column"}}>
                <NavBar/>
                <div className='menu-container' style={{flexGrow: 1}}>
                    <ContentsMenu sections={sections}/>
                </div>
                <div className="small-text">verze z {version}</div>
                <FooterMini />
            </div>
            <div className="page" style={{flexBasis: isHistogramApp ? '0' : '6', flexGrow: 6}}>
                <Outlet />
            </div>
        </div>
    );
};

function routerChild(section: Section) {
    return {
        path: section.path,
        element: section.element(section.sectionNumber),
    }

}

const router = createBrowserRouter([
    {
        path: Path.root,
        element: <AppLayout/>,
        children: [
            {
                path: '',
                element: (
                    <LandingPage/>
                )
            },
            routerChild(AnglesMeta),
            routerChild(BasicsEquationsMeta),
            routerChild(Derivatives1Meta),
            routerChild(FunctionGraphTransformations2Meta),
            routerChild(FunctionGraphTransformationsMeta),
            routerChild(FunctionLineMeta),
            routerChild(FunctionNotationMeta),
            routerChild(FunctionParityMeta),
            routerChild(FunctionQuadraticMeta),
            routerChild(FunctionsNotesMeta),
            routerChild(GoniometricMeta),
            routerChild(LimitsMeta),
            routerChild(SectionBasicsMeta),
            routerChild(SectionDerivativesMeta),
            routerChild(SectionFunctionsMeta),
            routerChild(SectionIntegrationMeta),
            routerChild(SubstitutionMeta),
            routerChild(TangentLineMeta),
            routerChild(Integration1Meta),
        ],
    }
]);


export default function App() {
    return (
        <RouterProvider router={router} />
    );
}



// vzorec je jen zápisem mentálního obrazu toho, co děláme
// nedosazujeme bezduše do vzorce
//  manipulace vzorce je technická /bez představy/, i když i to lze na
// určitých mezivýsledcích a určitě na finálním výsledku

//
// PAMATUJ SI, ŽE NEPises ucenbinci,
// ale manual k typovym prikladum ktere pokryva cviko
//